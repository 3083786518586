import axiosRequest from "@/utils/axiosRequest";

export function getDetail(data) {
  return axiosRequest({
    url: `/config/getDetail`,
    data,
    method: "post"
  });
}

export function update(data) {
  return axiosRequest({
    url: `/config/update`,
    data,
    method: "post"
  });
}

export function addDip(data) {
  return axiosRequest({
    url: `/config/addDip`,
    data,
    method: "post"
  });
}

export function updateDip(data) {
  return axiosRequest({
    url: `/config/updateDip`,
    data,
    method: "post"
  });
}

export function delDip(data) {
  return axiosRequest({
    url: `/config/delDip`,
    data,
    method: "post"
  });
}

export function listRpa(data) {
  return axiosRequest({
    url: `/config/rpaList`,
    data,
    method: "post"
  });
}

export function rpaDetail(data) {
  return axiosRequest({
    url: `/config/rpaDetail`,
    data,
    method: "post"
  });
}

export function addRpa(data) {
  return axiosRequest({
    url: `/config/addRpa`,
    data,
    method: "post"
  });
}

export function updateRpa(data) {
  return axiosRequest({
    url: `/config/updateRpa`,
    data,
    method: "post"
  });
}

export function delRpa(data) {
  return axiosRequest({
    url: `/config/delRpa`,
    data,
    method: "post"
  });
}