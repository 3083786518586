<template>
  <div>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span style="font-weight: bold;font-size:16px;">設定画面</span>
      </div>
      <el-form ref="form" :model="form" label-width="100px" :rules="rules" class="pp-customer">
        <!--メール-->
        <el-divider content-position="left">
          <span class="sub-title">メール</span>
        </el-divider>
        <el-row>
          <el-col :span="8">
            <el-form-item label="処理結果メール通知宛先 (TO)" prop="resultEmailTo" label-width="210px">
              <el-input size="small" v-model="form.resultEmailTo" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="処理結果メール通知宛先 (CC)" prop="resultEmailCc" label-width="210px">
              <el-input size="small" v-model="form.resultEmailCc" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!--クラウドサイン-->
        <el-divider content-position="left">
          <span class="sub-title">クラウドサイン</span>
        </el-divider>
        <el-row>
          <el-col :span="8">
            <el-form-item label="URL" prop="cloudsignUrl">
              <el-input size="small" v-model="form.cloudsignUrl" style="width: 92%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Basic認証ID" prop="cloudsignBasicId">
              <el-input size="small" v-model="form.cloudsignBasicId" style="width: 92%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Basic認証PW" prop="cloudsignBasicPw">
              <el-input size="small" v-model="form.cloudsignBasicPw" style="width: 92%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="ログインID" prop="cloudsignLoginId">
              <el-input size="small" v-model="form.cloudsignLoginId" style="width: 92%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="ログインPW" prop="cloudsignLoginPw">
              <el-input size="small" v-model="form.cloudsignLoginPw" style="width: 92%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!--SAAS-->
        <el-divider content-position="left">
          <span class="sub-title">SAAS</span>
        </el-divider>
        <el-row>
          <el-col :span="8">
            <el-form-item label="URL" prop="saasUrl">
              <el-input size="small" v-model="form.saasUrl" style="width: 92%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="ログインID" prop="saasLoginId">
              <el-input size="small" v-model="form.saasLoginId" style="width: 92%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="ログインPW" prop="saasLoginPw">
              <el-input size="small" v-model="form.saasLoginPw" style="width: 92%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!--BIMS-->
        <el-divider content-position="left">
          <span class="sub-title">BIMS</span>
        </el-divider>
        <el-row>
          <el-col :span="8">
            <el-form-item label="ログインID" prop="bimsLoginId">
              <el-input size="small" v-model="form.bimsLoginId" style="width: 92%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="ログインPW" prop="bimsLoginPw">
              <el-input size="small" v-model="form.bimsLoginPw" style="width: 92%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!--DIP-->
        <el-divider content-position="left">
          <span class="sub-title">DIP</span>
        </el-divider>
        <el-row>
          <el-col :span="8">
            <el-form-item label="URL" prop="dipUrl">
              <el-input size="small" v-model="form.dipUrl" style="width: 92%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="15" style="text-align: right;margin-top: 6px;">
            <el-button size="small" type="primary" icon="el-icon-plus" round @click="addDipTemp">新規</el-button>
          </el-col>
        </el-row>
        <!--DIP ACCOUNT テーブル-->
        <el-table :data="form.dipAccountList" border stripe class="pp-table">
          <el-table-column prop="sectionName" label="部署名" min-width="200px"></el-table-column>
          <el-table-column prop="baseName" label="拠点名" min-width="200px"></el-table-column>
          <el-table-column prop="loginId" label="ID" min-width="200px"></el-table-column>
          <el-table-column prop="loginPw" label="パスワード" min-width="200px"></el-table-column>
          <el-table-column :label="$t('task.process')" min-width="150px">
            <template slot-scope="scope">
              <el-button type="success" size="mini" plain round @click="handleUpdate(scope.row)">修正</el-button>
              <el-button type="danger" size="mini" plain round @click="handleDelete(scope.row)">削除</el-button>
            </template>
          </el-table-column>
        </el-table>

         <!--按钮部-->
        <el-form-item style="text-align: right; margin-top: 20px;">
          <el-button type="success" round @click="handleImport" style="margin-right: 5px;">DIP担当者インポート</el-button>
          <el-button type="primary" round @click="submitForm('form')" style="width: 120px;margin-right: 25px;">更 新</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!--DIPアカウントテンプ-->
    <el-dialog title="DIPアカウント" :visible.sync="dipForm.isVisible" width="50%">
      <el-form ref="dipForm" :model="dipForm" label-width="110px" :rules="dipRules" class="pp-service">
        <el-row>
          <el-col :span="12">
            <el-form-item label="部署名" prop="sectionName">
              <el-input size="small" v-model="dipForm.sectionName" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="ログインID" prop="loginId">
              <el-input size="small" v-model="dipForm.loginId" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="拠点名" prop="baseName">
              <el-input size="small" v-model="dipForm.baseName" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="ログインPW" prop="loginPw">
              <el-input size="small" v-model="dipForm.loginPw" style="width: 90%;"></el-input>
            </el-form-item>
          </el-col>
        </el-row> 
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="addDip('dipForm')" v-if="dipForm.mode == 0">確認</el-button>
        <el-button type="primary" round @click="updateDip('dipForm')" v-if="dipForm.mode == 1">更新</el-button>
        <el-button round @click="dipForm.isVisible = false">{{$t('addCustomer.back')}}</el-button>
      </div>
    </el-dialog>

    <!--DIPユーザーテンプ-->
    <el-dialog :visible.sync="importModel.show" width="500px">
      <div slot="title">データインポート</div>
      <div class="createDialog">
        <div>
          <el-upload
            ref="upload"
            accept=".xlsx"
            :action="doTextImport"
            :limit="1"
            :file-list="importModel.fileList"
            :on-success="handleImportSuccess"
            :on-change="handleImportChange"
            :auto-upload="false">
            <el-button size="small" type="warning" round style="width:100px;">ファイル選択</el-button>
            <span style="padding-left:20px;">毎回一つxlsxファイルのみインポートできます。</span>
          </el-upload>
        </div>
        <div><label v-show="importModel.errmsg!=''" style="color:red;">{{importModel.errMsg}}</label></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" round @click="importModel.show = false">キャンセル</el-button>
        <el-button size="small" type="primary" round @click.native.prevent="submitImport" style="width:90px;">確 認</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDetail, update, addDip, updateDip, delDip } from "@/api/config";
import { mapState } from "vuex";

export default {
  name: "SysConfig",
  data: () => {
    return {
      form: {
        id: null,
        resultEmailTo: "",
        resultEmailCc: "", 
        cloudsignUrl: "",
        cloudsignBasicId: "",
        cloudsignBasicPw: "",
        cloudsignLoginId: "",
        cloudsignLoginPw: "",
        saasUrl: "",
        saasLoginId: "", 
        saasLoginPw: "",
        bimsLoginId: "",
        bimsLoginPw: "",
        dipUrl: "",
        employId: null,
        groupId: null,
        dipAccountList: null
      },
      dipForm: {
        mode: 0,
        isVisible: false,
        id: null,
        sectionName: null,
        baseName: null,
        loginId: null,
        loginPw: null,
      },
      doTextImport:process.env.VUE_APP_BASE_API + "/config/importDip",
      importModel:{
          show:false,
          errMsg:'',
          fileList: []
      },
      dipRules: {
        sectionName: [
          { required: true, message: "部署名を入力してください。", trigger: "blur" }
        ],
        baseName: [
          { required: true, message: "拠点名を入力してください。", trigger: "blur" }
        ],
        loginId: [
          { required: true, message: "ログインIDを入力してください。", trigger: "blur" }
        ],
        loginPw: [
          { required: true, message: "ログインPWを入力してください。", trigger: "blur" }
        ],
      },
    };
  },
  mounted() {
    this.refreshData();
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    refreshData() {
      getDetail({
      }).then(response => {
        this.form = response.data;
      });
    },
    addDipTemp() {
      if (this.$refs['dipForm'] !== undefined) {
        this.$refs['dipForm'].resetFields();
      }
      this.dipForm.mode = 0;
      this.dipForm.isVisible = true;
      this.dipForm.id = null;
      this.dipForm.sectionName = null;
      this.dipForm.baseName = null;
      this.dipForm.loginId = null;
      this.dipForm.loginPw = null;
    },
    addDip(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('DIPアカウントを登録します。よろしいですか？', '提示', {
              confirmButtonText: '確認',
              cancelButtonText: '戻る',
              type: 'warning'
          }).then(() => {
            var data = {
              sectionName: this.dipForm.sectionName,
              baseName: this.dipForm.baseName,
              loginId: this.dipForm.loginId,
              loginPw: this.dipForm.loginPw
            }

            addDip(data).then(response => {
              this.$message({
                message: response.message,
                type: "success"
              });
              this.dipForm.isVisible = false;
              this.refreshData();
            });
          });
        } else {
          return false;
        }
      });
    },
    handleUpdate(entity) {
      if (this.$refs['dipForm'] !== undefined) {
        this.$refs['dipForm'].resetFields();
      }
      this.dipForm.mode = 1;
      this.dipForm.isVisible = true;
      this.dipForm.id = entity.id;
      this.dipForm.sectionName = entity.sectionName;
      this.dipForm.baseName = entity.baseName;
      this.dipForm.loginId = entity.loginId;
      this.dipForm.loginPw = entity.loginPw;
    },
    updateDip(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('DIPアカウントを更新します。よろしいですか？', '提示', {
              confirmButtonText: '確認',
              cancelButtonText: '戻る',
              type: 'warning'
          }).then(() => {
            var data = {
              id: this.dipForm.id,
              sectionName: this.dipForm.sectionName,
              baseName: this.dipForm.baseName,
              loginId: this.dipForm.loginId,
              loginPw: this.dipForm.loginPw
            }

            updateDip(data).then(response => {
              this.$message({
                message: response.message,
                type: "success"
              });
              this.dipForm.isVisible = false;
              this.refreshData();
            });
          });
        } else {
          return false;
        }
      });
    },
    handleDelete(entity) {
      this.$confirm('DIPアカウントを削除します。よろしいですか？', '提示', {
          confirmButtonText: '確認',
          cancelButtonText: '戻る',
          type: 'warning'
      }).then(() => {
        var data = {
          id: entity.id
        }
        delDip(data).then(response => {
          this.$message({
            message: response.message,
            type: "success"
          });
          this.refreshData();
        });
      });
    },
    handleImport() {
      this.importModel.show = true;
      this.importModel.errMsg = "";
      this.importModel.fileList = [];
    },
    handleImportChange(file, fileList) {
      this.importModel.errMsg = "";
      this.importModel.fileList = fileList;
    },
    handleImportSuccess(response, file, fileList) {
      this.importModel.show = false;
      this.refreshData();
    },
    submitImport(param) {
      this.importModel.errMsg = "";
      if (this.importModel.fileList.length <= 0) {
        this.importModel.errMsg = "ファイルを選択してください";
        return;
      }
      this.$confirm("ファイルをインポートしてよろしいでしょうか？", this.$t('common.popup'), {
        confirmButtonText: '確認',
        cancelButtonText: '戻る',
        type: 'warning'
      }).then(() => {
        this.$refs.upload.submit();
      }).catch(() => {
      });
    },
    submitForm(formName){
      this.$confirm('システム設定を更新します。よろしいですか？', '提示', {
          confirmButtonText: '確認',
          cancelButtonText: '戻る',
          type: 'warning'
      }).then(() => {
        var data = this.form;
        data.employId = this.accountInfo.id;
        data.groupId = this.accountInfo.groupId;

        update(data).then(response => {
          this.$message({
            message: response.message,
            type: "success"
          });
          this.refreshData();
        });
      });
    },
  }
};
</script>
<style>
  .searchBar {
    margin: 0px 0px 10px 0px;
    /*padding: 10px 10px ;*/
    /*background-color: #f7f7f7;*/
    text-align: right;
  }
  .pp-customer .el-form-item {
      margin-bottom: 20px;
  }
  .pp-service .el-form-item {
      margin-bottom: 20px;
  }
  .pp-table {
      width: 95%;
      margin-left: 60px;
      margin-bottom: 50px;
  }
  </style>
